import React from 'react';
import styles from '../styles/styles.module.css';
import teamStyles from '../styles/components/meet-the-team.module.css';
import { TeamCard } from './card';


const MeetTheTeamContainer = () => {
    
    return (
        <section className={`${teamStyles.team__container} ${styles.center__content} ${styles.text__center}`}>

            <h2 id="team">Meet Our Team</h2>
            <p> 
                Each expert brings a unique 
                blend of brilliance and dedication, united by a passion for crafting digital dreams 
                into enduring realities. Trust your innovation to minds that push boundaries, not buttons. 
            </p>

            <section className={teamStyles.team_cards}>
                <TeamCard 
                    name={'Darryl Myers'} 
                    jobTitle={'Software Engineer'} 
                    socialLinks={[
                        { 
                            linkedIn: '', 
                            twitter: '', 
                            facebook: '', 
                            stackoverflow: '' 
                        }
                    ]} 
                    profilePicture={'/assets/team-members/darryl-employee.jpg'} 
                />
                
                <TeamCard 
                    name={'Ben Garcia'} 
                    jobTitle={'Software Engineer'} 
                    socialLinks={[
                        { 
                            linkedIn: '', 
                            twitter: '', 
                            facebook: '', 
                            stackoverflow: '' 
                        }
                    ]} 
                    profilePicture={'/assets/team-members/ben-employee.jpg'} />

                <TeamCard 
                    name={'Zach Davies'} 
                    jobTitle={'QA Tester'} 
                    socialLinks={[
                        { 
                            linkedIn: '', 
                            twitter: '', 
                            facebook: '', 
                            stackoverflow: '' 
                        }
                    ]} 
                    profilePicture={'/assets/team-members/zechariah-employee.jpg'} 
                />

                <TeamCard 
                    name={'Coralie Dumot'} 
                    jobTitle={'UX/UI Designer'} 
                    socialLinks={[
                        { 
                            linkedIn: '', 
                            twitter: '', 
                            facebook: '', 
                            stackoverflow: '' 
                        }
                    ]} 
                    profilePicture={'/assets/team-members/coralie-employee.jpg'} 
                />
            </section>

        </section>
    );
  };
  
  export default MeetTheTeamContainer;