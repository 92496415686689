import React from 'react';
import testimonialStyles from '../styles/components/testimonial.module.css';
import styles from '../styles/styles.module.css';

interface TestimonialProps {
    type: 'red' | 'black'
    image: string;
    name: string;
    jobTitle: string;
    paragraph: string;
}

const Testimonial = (props: TestimonialProps) => {
  const backgroundType = props.type === 'red';

  return (
    <section data-aos="zoom-out-up" className={`${styles.grid} ${styles.grid__1x2} ${testimonialStyles.testimonial__container}`}>

        <div className={`${testimonialStyles.testimonial__image__container} ${styles.center__content}`}>
            <img 
                src={props.image} 
                // eslint-disable-next-line max-len
                alt="Delighted client showcasing successful collaboration with Iditium - Leaders in Web Development, 
                    Mobile App Creation, and Cloud Infrastructure Solutions. 
                    Elevate your digital presence with our top-notch services, 
                    proven to exceed expectations." 
                className={`
                    ${testimonialStyles.testimonial__image} 
                    ${backgroundType ? testimonialStyles.red__border : testimonialStyles.black__border}`}
            />
        </div>
        
        <article className={testimonialStyles.testimonial__information}>
            <h6 
                className={`
                    ${testimonialStyles.testimonial__heading} 
                    ${backgroundType ? testimonialStyles.red__text__color : testimonialStyles.black__text__color}
                `}>
                    {props.name} Testimonial
            </h6>

            <p className={testimonialStyles.testimonial__text}>
                {props.paragraph}
            </p>

            <p className={`
                ${testimonialStyles.testimonial__name}
                ${backgroundType ? testimonialStyles.red__text__color : testimonialStyles.black__text__color}
            `}>
                {props.name}
            </p>
            <p className={testimonialStyles.testimonial__job__title}>
                {props.jobTitle}
            </p>
        </article>

        <div className={`
            ${testimonialStyles.testimonial__apostrophe} 
            ${backgroundType ? testimonialStyles.blue_background : testimonialStyles.black__background}`}
        >
            <svg xmlns="http://www.w3.org/2000/svg" className={testimonialStyles.apostrophe__icon}>
                <use xlinkHref="/assets/sprite.svg#apostrophe"></use>
            </svg>
        </div>
    </section>
  );
};

export default Testimonial;
