import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import homeStyles from '../styles/pages/home.module.css';
import cardStyles from '../styles/components/card.module.css';
import heroStyles from '../styles/components/hero.module.css';
import Header from '../components/layouts/header';
import Button from '../components/inputs/button';
import styles from '../styles/styles.module.css';
import { ClientContainer, ClientLogo } from '../components/client-container';
import SoftwareContainer from '../components/software-container';
import { Card } from '../components/card';
import InformationComponent from '../components/information-box';
import Footer from '../components/layouts/footer';
import Testimonial from '../components/testimonial';
import FAQComponent from '../components/frequently-asked-questions';
import FeatureBenefit from '../components/feature-benefit';
import VideoModal from '../components/modal';
import MetaData from '../components/layouts/meta-data';

const Home = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const navigate = useNavigate();

  function navigateToContactUs() {
    navigate('/contact-us');
  }

  function navigateToAboutUs() {
    navigate('/about-us');
  }

  function navigateToInPageLink() {
    // eslint-disable-next-line no-undef
    navigate('/about-us#information');
  }

  const handleVideoToggle = () => {
    setIsVideoVisible(!isVideoVisible);
  };

  return (
    <main>

      <MetaData 
        title={'Iditium - Transforming Visions into Digital Excellence'} 
        description={`
          Discover digital innovation with Iditium, your premier partner in 
          crafting dynamic online experiences. Specializing in bespoke website development, mobile app development, 
          and scalable cloud infrastructure solutions, we propel businesses to new heights of success. 
          Our global expertise ensures tailored strategies that resonate with the evolving digital landscape, 
          guaranteeing a competitive edge. Elevate your brand with Iditium's commitment to excellence, 
          where every project is a fusion of creativity, technology, and unparalleled client satisfaction.
        `} 
      />

      <section className={heroStyles.header}>
        <Header />
      </section>

      <section className={homeStyles.background}>
        <p className={homeStyles.discover__text}>
          <span className={homeStyles.discover__block}>Discover </span> Iditium
        </p>
        <h1 className={homeStyles.iditium__title}>IDITIUM SOFTWARE</h1>
        <h2>Build High Quality Custom Software</h2>
        <p className={homeStyles.iditium__paragraph}>
          Elevate your business with Iditium's expertise in building reliable
          and scalable <br /> software solutions that empower growth.
        </p>

        <div className={styles.row}>
          <Button
            title={'Learn More'}
            onClick={navigateToInPageLink}
            buttonType={'primary'}
            classNames={homeStyles.btn__spacing}
          />
          <Button
            title={'Contact Us'}
            onClick={navigateToContactUs}
            buttonType={'header'}
          />
        </div>

        <div className={homeStyles.iditium__featured}>
          <span>Featured on</span>

          <div className={styles.row}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={homeStyles.icon__dribble}
            >
              <use xlinkHref="/assets/company-icons.svg#dribble"></use>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={homeStyles.icon__wordpress}
            >
              <use xlinkHref="/assets/company-icons.svg#wordpress"></use>
            </svg>
          </div>
        </div>
      </section>

      <section
        className={`${styles.grid} ${styles.grid__columns} ${homeStyles.software__container}`}
      >
        <div data-aos="flip-up" className={homeStyles.column__1}>
          <img
            src="/assets/iditium-information.png"
            className={homeStyles.information__img}
            alt="Empower your global presence with Iditium's expertise in web 
                        development, mobile apps, and cloud infrastructure solutions."
          />
        </div>

        <div data-aos="flip-up" className={homeStyles.column__2}>
          <h3>Software Development</h3>
          <h2>
            <span className={homeStyles.black__text}>Build Your Software </span>
            With Industry Experts
          </h2>
          <p>
            Iditium is your trusted software powerhouse that brings together
            industry experts to design, develop, and maintain cutting-edge
            websites, mobile apps, and cloud infrastructure.
          </p>
          <Button
            title={'Learn More'}
            onClick={navigateToAboutUs}
            buttonType={'primary'}
          />
        </div>
      </section>

      <ClientContainer />

      <section
        className={`${styles.padding__container} ${styles.remove__padding__top} ${styles.grid} ${styles.grid__1x2}`}
      >
        <div data-aos="zoom-in-right" className={styles.row}>
          <p className={homeStyles.feature__number}>01</p>

          <div className={styles.column}>
            <h3 className={homeStyles.feature__heading}>
              Software Development
            </h3>
            <p className={homeStyles.feature__paragraph}>
              Our team of experienced developers and designers will work closely
              with you to understand your unique requirements and deliver a
              tailor-made solution that meets your exact specifications.
            </p>
            <FeatureBenefit
              title={'Tailored solutions for your needs'}
              // eslint-disable-next-line max-len
              description={
                'Custom-built software that perfectly fits your unique requirements, ensuring maximum efficiency and effectiveness.'
              }
            />
            <FeatureBenefit
              title={'Seamless integration across platforms'}
              // eslint-disable-next-line max-len
              description={
                'Seamlessly connect your website, mobile apps, software, and cloud infrastructure for a cohesive and streamlined user experience.'
              }
            />
            <FeatureBenefit
              title={'Trust in our expertise'}
              // eslint-disable-next-line max-len
              description={
                'Rely on our experienced team to handle all aspects of software development, from design to deployment, ensuring high-quality results every time.'
              }
            />
            <Button
              title={'Watch Demo'}
              onClick={handleVideoToggle}
              buttonType={'feature'}
            />
            <VideoModal
              isVideoVisible={isVideoVisible}
              handleVideoToggle={handleVideoToggle}
            />
          </div>
        </div>

        <div
          data-aos="zoom-in-left"
          className={`${styles.center__content} ${homeStyles.padding_top}`}
        >
          <img
            src="/assets/software-development.png"
            className={homeStyles.feature__img}
            alt="Collaborative excellence in action: Our dedicated team crafting innovative software solutions for your success."
          />
        </div>
      </section>

      <section
        className={`
            ${homeStyles.web__development__container}
            ${styles.grid} 
            ${styles.grid__1x2} 
        `}
      >
        <div
          data-aos="zoom-in-right"
          className={`${homeStyles.padding_top} ${styles.center__content} ${styles.reverse__layout}`}
        >
          <img
            src="/assets/web-development.png"
            className={homeStyles.feature__img}
            alt="Iditium's skilled team at work—crafting exceptional 
                        websites through expert design and development for your digital success."
          />
        </div>

        <div
          data-aos="zoom-in-left"
          className={`${styles.row} ${homeStyles.row__reverse} ${homeStyles.text__right}`}
        >
          <p className={homeStyles.feature__number}>02</p>

          <div className={styles.column}>
            <h3 className={homeStyles.feature__heading}>Web Development</h3>
            <p className={homeStyles.feature__paragraph}>
              Our team of skilled professionals will craft visually stunning
              websites that are not only aesthetically pleasing but also
              optimized for performance, ensuring a seamless user experience.
            </p>
            <FeatureBenefit
              title={'Customized Solutions for You'}
              // eslint-disable-next-line max-len
              description={
                'Get tailor-made websites and apps that perfectly align with your unique business needs and objectives.'
              }
              reversed={true}
            />
            <FeatureBenefit
              title={'Seamless User Experience'}
              // eslint-disable-next-line max-len
              description={
                'Trust Iditium to create a user-friendly website that ensures smooth navigation, engages visitors, and enhances conversions.'
              }
              reversed={true}
            />
            <FeatureBenefit
              title={'Faster time to market'}
              // eslint-disable-next-line max-len
              description={
                'With Iditium, get your website up and running in no time, allowing you to reach your audience faster and stay ahead of the competition.'
              }
              reversed={true}
            />
            <Button
              title={'Watch Demo'}
              onClick={handleVideoToggle}
              buttonType={'feature'}
              classNames={homeStyles.btn__right}
            />
          </div>
        </div>
      </section>

      <section
        className={`${styles.padding__container} ${styles.grid} ${styles.grid__1x2}`}
      >
        <div data-aos="zoom-in-right" className={styles.row}>
          <p className={homeStyles.feature__number}>03</p>

          <div className={styles.column}>
            <h3 className={homeStyles.feature__heading}>
              Mobile App Development
            </h3>
            <p className={homeStyles.feature__paragraph}>
              At Iditium, we design and develop intuitive, pixel-perfect apps
              that elevate your brand and propel user engagement.
            </p>
            <FeatureBenefit
              title={'Expertise in Mobile App Development'}
              // eslint-disable-next-line max-len
              description={
                'Innovative mobile app solutions tailored to your needs.'
              }
            />
            <FeatureBenefit
              title={'Innovation that sets you apart'}
              // eslint-disable-next-line max-len
              description={
                'Stay ahead of the competition with our cutting-edge technologies and innovative solutions for your mobile app development needs.'
              }
            />
            <FeatureBenefit
              title={'Seamless Cross-Platform Integration'}
              // eslint-disable-next-line max-len
              description={
                'Effortless deployment across platforms for wider audience reach and consistent user experience.'
              }
            />
            <Button
              title={'Watch Demo'}
              onClick={handleVideoToggle}
              buttonType={'feature'}
            />
          </div>
        </div>
        <div
          data-aos="zoom-in-left"
          className={`${styles.center__content} ${homeStyles.padding_top}`}
        >
          <img
            src="/assets/mobile-development.png"
            className={homeStyles.feature__img}
            alt="Iditium's dynamic team in action, shaping a cutting-edge mobile 
                        app through seamless design and development for a standout user experience."
          />
        </div>
      </section>

      <SoftwareContainer />

      <section
        className={`${homeStyles.iditium__container} ${styles.grid} ${styles.grid__1x2}`}
      >
        <div className={homeStyles.select__container}>
          <h3>IDITIUM</h3>
          <h2>Why You Should Select Us</h2>
          <p>
            Iditium is more than just services. We're a team of passionate,
            results-oriented professionals who are dedicated to your success. We
            believe in building strong partnerships with our clients, taking the
            time to understand your business goals and challenges before
            crafting a custom solution that delivers tangible results. Choose
            Iditium - let's code your success story.
          </p>
          <Button
            title={'Contact Us'}
            onClick={navigateToContactUs}
            buttonType={'primary'}
          />
        </div>

        <div className={homeStyles.card__container}>
          <Card
            imgUrl={'/assets/budget-friendly.png'}
            title={'Budget Friendly'}
            // eslint-disable-next-line @typescript-eslint/quotes
            paragraph={"Iditium delivers cost-conscious software excellence."}
            classNames={cardStyles.ellipse_bottom_right}
          />
          <Card
            imgUrl={'/assets/cpu.png'}
            title={'Cloud Confident'}
            paragraph={
              'Migrate & manage, worry-free with our cloud infrastructure expertise.'
            }
            classNames={cardStyles.ellipse_bottom_left}
          />
          <Card
            imgUrl={'/assets/expert-hacker.png'}
            title={'Tailor-made Software'}
            paragraph={
              'Ditch one-size-fits-all, craft digital magic unique to you.'
            }
            classNames={cardStyles.ellipse_top_right}
          />
          <Card
            imgUrl={'/assets/customer-support.png'}
            title={'Passionate Partners'}
            paragraph={
              'We don\'t just build software, we build your success story.'
            }
            classNames={cardStyles.ellipse_top_left}
            redCard={true}
          />
        </div>
      </section>

      <InformationComponent
        redBackground={true}
        subheading={'Process'}
        heading={'How Our Process Works'}
        paragraph={`
                    At Iditium, we transform digital dreams into tangible realities through a meticulous, 
                    collaborative process. We begin by partnering with you, meticulously uncovering your 
                    goals and challenges. This deep understanding fuels the design phase, where our experts 
                    craft elegant blueprints that translate your vision into pixel-perfect functionality. 
                    Iterative refinement ensures each step aligns with your expectations, paving the way for a 
                    robust software architecture. Our skilled developers then breathe life into the blueprints, 
                    bringing your ideal solution to fruition with unwavering commitment to quality and performance. 
                    Finally, we unveil your masterpiece, ready to launch your digital journey and ignite your success.`}
      />

      <section
        className={`${styles.grid} ${styles.grid__1x2} ${styles.padding__container} ${styles.remove__padding__top}`}
      >
        <div className={homeStyles.client__center}>
          <h3>Client</h3>
          <h2>Our Featured Clients</h2>
          <p>
            From global giants to bold startups, over 46 brands trust Iditium to
            unlock their digital potential. Join our collective of innovators
            and watch your aspirations take flight.
          </p>
        </div>

        <div
          data-aos="zoom-in-left"
          className={`${styles.grid} ${homeStyles.clients__container}`}
        >
          <ClientLogo img={'/assets/clients/active-campaign.png'} />
          <ClientLogo img={'/assets/clients/mail-champ.png'} />
          <ClientLogo img={'/assets/clients/skipio.png'} />

          <ClientLogo img={'/assets/clients/calldrip.png'} />
          <ClientLogo img={'/assets/clients/skybroadcast.png'} />
          <ClientLogo img={'/assets/clients/click-send.png'} />

          <ClientLogo img={'/assets/clients/circle-logo.png'} />
          <ClientLogo img={'/assets/clients/pipedrive.png'} />
          <ClientLogo img={'/assets/clients/call-rail.png'} />
        </div>
      </section>

      {/* FAQ */}
      <section className={styles.padding__container}>
        <h2 className={styles.text__center}>Frequently Asked Questions</h2>
        <p className={`${styles.text__center} ${styles.padding__text}`}>
          Unsure of a pixel? Bewildered by a byte? Dive into our insightful
          FAQs! Find clarity, quell doubts, and embark on your digital odyssey
          with confidence.
        </p>

        <div className={`${styles.column} ${styles.center__content}`}>
          <FAQComponent
            question={'Do you also design websites and mobile apps?'}
            answer={`Yes, we do! Our team of experienced designers and developers can craft stunning and user-friendly websites and 
                            mobile apps that not only look great but also drive results. 
                            We'll work closely with you to understand your vision and target audience, 
                            then create a digital solution that exceeds your expectations.`}
          />
          <FAQComponent
            question={'What cloud platforms do you work with?'}
            answer={`We have extensive experience working with a variety of cloud platforms, including Amazon Web Services (AWS), 
                            Microsoft Azure, and Google Cloud Platform (GCP). We'll recommend the best platform for your specific needs 
                            and requirements, ensuring a smooth and secure cloud migration.`}
          />
          <FAQComponent
            question={'Do you offer ongoing support and maintenance?'}
            answer={`Yes, we offer comprehensive ongoing support and maintenance plans to ensure your website, mobile app, 
                            or cloud infrastructure is always running smoothly and securely. Our team of experts is available 
                            to answer your questions and resolve any issues that may arise.`}
          />
          <FAQComponent
            question={'What are your rates at Iditium?'}
            answer={`Our rates vary depending on the scope and complexity of your project. 
                            We offer competitive pricing and transparent quotes, so you can be sure you're 
                            getting the best value for your investment.

                            We encourage you to contact us for a free consultation to discuss your specific needs and get a 
                            custom quote. We're confident that we can provide you with a cost-effective solution that meets your business goals.`}
          />
        </div>
      </section>

      <section className={styles.padding__container}>
        <h2 className={styles.text__center}>What Client Say About Us</h2>
        <p className={`${styles.text__center} ${styles.padding__text}`}>
          Let our clients tell you their Iditium story.
        </p>
        <div className={homeStyles.testimonials}>
          <Testimonial
            type={'black'}
            image={'assets/iditium-clients/amara-client.jpg'}
            name={'Amara Petrova'}
            jobTitle={'Head of Robotics and Automation'}
            paragraph={`Iditium wasn't just a coding team, they were our innovation architects. They transformed 
                                our vision for a data-driven marketing platform into a reality that exceeded our wildest expectations.`}
          />
          <Testimonial
            type={'red'}
            image={'assets/iditium-clients/jasper-client.jpg'}
            name={'Jasper Knight'}
            jobTitle={'Director'}
            paragraph={`Working with Iditium was a breath of fresh air. They embraced our agile methodology, 
                                collaborating seamlessly with our internal teams and consistently adapted to evolving requirements. `}
          />
          <Testimonial
            type={'red'}
            image={'assets/iditium-clients/orion-client.jpg'}
            name={'Orion Jackson'}
            jobTitle={'Head of Blockchain Development'}
            paragraph={`We had a limited budget and ambitious goals, but Iditium never flinched. 
                                They worked within our constraints, prioritizing features that delivered maximum impact.`}
          />
          <Testimonial
            type={'black'}
            image={'assets/iditium-clients/zana-client.jpg'}
            name={'Zane Patel'}
            jobTitle={'Chief Innovation Officer'}
            paragraph={`Iditium exceeded my expectations with their software and cloud solutions. 
                        Professional, efficient, and innovative. Highly recommend!`}
          />
        </div>
      </section>

      <Footer />
    </main>
  );
};

export default Home;
