import React from 'react';
import { Helmet } from 'react-helmet-async';

type MetaDataProps = {
    title: string;
    description: string;
}

const MetaData = (props: MetaDataProps) => {
  return (
    <Helmet>
        <meta
            name="description"
            content={props.description}
        />
        <meta
            property="og:title"
            content={props.title}
        />
        <meta
            property="og:description"
            content={props.description}
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://iditium.com/assets/Logos/iditium.png" />
        <meta property="og:url" content="https://iditium.com" />

        <title>{props.title}</title>
    </Helmet>
  );
};

export default MetaData;