import React from 'react';
import styles from '../styles/styles.module.css';
import logoStyles from '../styles/components/client-container.module.css';

export const ClientContainer = () => {
    return (
        <section className={`${styles.row} ${logoStyles.logo__container}`}>
          <svg xmlns="http://www.w3.org/2000/svg" className={logoStyles.logo__icon}>
              <use xlinkHref="/assets/company-icons.svg#yahoo"></use>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className={logoStyles.logo__icon}>
              <use xlinkHref="/assets/company-icons.svg#bbc"></use>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className={logoStyles.logo__icon}>
              <use xlinkHref="/assets/company-icons.svg#forbes"></use>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className={logoStyles.logo__icon}>
              <use xlinkHref="/assets/company-icons.svg#adweek"></use>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className={logoStyles.logo__icon}>
              <use xlinkHref="/assets/company-icons.svg#almar"></use>
          </svg>

          <svg xmlns="http://www.w3.org/2000/svg" className={logoStyles.logo__icon}>
              <use xlinkHref="/assets/company-icons.svg#today"></use>
          </svg>
        </section>
    );
  };

interface ClientLogoProps {
    img: string;
}

export function ClientLogo(props: ClientLogoProps) {
    return (
        <div>
            <img 
                src={props.img}
                alt={`
                    A satisfied partner in our website, mobile app, and cloud 
                    infrastructure development services. Experience seamless 
                    digital transformation with our expertise, tailored to 
                    elevate your company's online presence.
                `} 
                className={logoStyles.client__logo}
            />
        </div>
    );
}