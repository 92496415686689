import React from 'react';
import buttonStyles from '../../styles/components/inputs/button.module.css';

export type ButtonProps = {
    title: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onClick: any;
    disabled?: boolean;
    buttonType: 'primary' | 'secondary' | 'header' | 'feature';
    classNames?: string | React.CSSProperties;
    maxWidth?: boolean;
    type?: 'button' | 'submit' | 'reset';
  }


const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
  const buttonStyle = buttonStyles[`${props.buttonType + 'Button'}`];

  return (
    <button 
      onClick={props.onClick} 
      className={`${props.classNames} ${buttonStyle} ${buttonStyles.btn} ${props.maxWidth ? buttonStyles.max__width : ''}`}
      >
        <span>{props.title}</span>
    </button>
  );
};

export default Button;