import React from 'react';
import cardStyles from '../styles/components/card.module.css';
import styles from '../styles/styles.module.css';
import { Link } from 'react-router-dom';

interface TeamCardProps {
    name: string;
    jobTitle: string;
    socialLinks: { linkedIn?: string, twitter?: string, facebook?: string, stackoverflow?: string }[];
    profilePicture: string;
}


export const TeamCard = (props: TeamCardProps) => {
    return (
        <section data-aos="fade-left" className={cardStyles.team__card}>
            <div className={cardStyles.team__ellipse}></div>

            <img 
                src={props.profilePicture} 
                alt="A profile picture of an Iditium Team Member that builds/design website and mobile apps" 
                className={cardStyles.team__card__img}
            />

            <h2 className={cardStyles.card__header}>{props.name}</h2>
            <p>{props.jobTitle}</p>

            <section className={cardStyles.card__icons}>
                <Link to={props.socialLinks[0].facebook ?? '/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.social__icon}>
                        <use xlinkHref="../../assets/social-media-icons.svg#facebook"></use>
                    </svg>
                </Link>
                <Link to={props.socialLinks[0].twitter ?? '/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.social__icon}>
                        <use xlinkHref="../../assets/social-media-icons.svg#twitter-x"></use>
                    </svg>
                </Link>
                <Link to={props.socialLinks[0].stackoverflow ?? '/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.social__icon}>
                        <use xlinkHref="../../assets/social-media-icons.svg#stack-overflow"></use>
                    </svg>
                </Link>
                <Link to={props.socialLinks[0].linkedIn ?? '/'}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={styles.social__icon}>
                        <use xlinkHref="../../assets/social-media-icons.svg#linkedin-icon"></use>
                    </svg>
                </Link>
            </section>
        </section>
    );
};

interface CardProps {
    imgUrl: string;
    title: string;
    paragraph: string;
    classNames?: string;
    redCard?: boolean;
}

export const Card = (props: CardProps) => {
    return (
        <div data-aos="zoom-in" className={`${cardStyles.card} ${props.redCard ? cardStyles.blue__background : ''}`}>
            <img 
                src={props.imgUrl} 
                className={cardStyles.card__img} 
                alt='Enhance business success with our expert web development, mobile apps, and cloud infrastructure solutions.' 
            />
            <h5 className={`${cardStyles.card__heading} ${props.redCard ? cardStyles.card__white__text : ''}`}>{props.title}</h5>
            <p className={`${cardStyles.card__text} ${props.redCard ? cardStyles.card__white__text : ''}`}>
                {props.paragraph}
            </p>

            <div className={`${cardStyles.ellipse} ${props.classNames}`}></div>
        </div>
    );
};
