import React, { useState } from 'react';
import headerStyles from '../../styles/components/layouts/header.module.css';
import styles from '../../styles/styles.module.css';
import Button from '../inputs/button';
import { Link, useNavigate } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const Header = () => {  
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  function navigateToContactUs() {
      navigate('contact-us');
  }

  const navHandler = () => {
    setIsExpanded(!isExpanded);
  };
  
  return (
    <header>
      <nav className={`${headerStyles.nav} ${isExpanded ? styles.collapsible__expanded : ''}`}>
        <Link aria-label="Iditium" className={headerStyles.nav__brand} to="/">
            <img src="/assets/IditiumLogo.png" alt="Iditium Logo"/>
        </Link>
        <svg xmlns="http://www.w3.org/2000/svg" className={`${styles.icon} ${styles.icon__white} ${headerStyles.nav__toggler}`} onClick={navHandler}>
          <use xlinkHref="/assets/sprite.svg#menu" />
        </svg>
        <ul className={`${styles.list} ${headerStyles.nav__list} ${styles.collapsible__content}`}>
            <Link className={headerStyles.nav__item} to='/'>
              {' Home '}
            </Link>
            <br />
            <Link className={headerStyles.nav__item} to='/about-us'>
              {' About '}
            </Link>
            <br />
            <HashLink className={headerStyles.nav__item} to='/about-us#information'>
              {' Services '}
            </HashLink>
            <br />
            <HashLink className={headerStyles.nav__item} to='/contact-us#team'>
              {' Career '}
            </HashLink>
            <br />
            <Link className={headerStyles.nav__item} to='/contact-us'>
                {' Contact Us '}
            </Link>
            <br />
            <div>
              <Button title={'Contact Us'} onClick={navigateToContactUs} buttonType={'header'} classNames={headerStyles.contact__button} />
            </div>
        </ul>
      </nav>
    </header>
  );
};

export default Header;