/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, FormEvent, useState } from 'react';
import emailjs from 'emailjs-com';
import HeroComponent from '../components/hero';
import contactStyles from '../styles/pages/contact-us.module.css';
import styles from '../styles/styles.module.css';
import Button from '../components/inputs/button';
import { ClientContainer } from '../components/client-container';
import MeetTheTeamContainer from '../components/meet-the-team';
import Footer from '../components/layouts/footer';
import { showFailureToast, showSuccessToast } from '../utils/Notification';
import { isValidEmail, isValidPhoneNumber } from '../utils/Validation';
import MetaData from '../components/layouts/meta-data';

const STATUS_OK = 200;

const ContactUs = () => {
    const [error, setError] = useState<string>('');
    const [contactDetails, setContactDetails] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        subject: '',
        details: ''
    });
    const { name, email, phoneNumber, subject, details} = contactDetails;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onChangeValue(e: any) {
        setContactDetails({ ...contactDetails, [e.target.name]: e.target.value });
    }

    const submitHandler = async (e: FormEvent) => {
        e.preventDefault();

        if(!name) {
            setError('Name is required');
            return;
        }

        if(!isValidEmail(email)) {
            setError('Please ensure your email is correct');
            return;
        }

        if(!isValidPhoneNumber(phoneNumber)) {
            setError('Please ensure your phone number is correct');
            return;
        }

        const formData = {
            name: name,
            email: email,
            phoneNumber: phoneNumber,
            subject: subject,
            details: details
        };

        const result = await sendEmail(formData);

        if(result) {
            setContactDetails({
                name: '',
                email: '',
                phoneNumber: '',
                subject: '',
                details: ''
            });
        }
    };

    async function sendEmail(formInfo: any) {
        const result = await emailjs.send(
                process.env.REACT_APP_SERVICE_ID ?? '', 
                process.env.REACT_APP_TEMPLATE_ID ?? '', 
                formInfo, 
                process.env.REACT_APP_USER_ID ?? '');

        if(result.status === STATUS_OK) {
            showSuccessToast('Success, we will be in touch');
            return true;
        }

        showFailureToast('Oops, something went wrong out our side. Try again.');
        return false;
    }


  return (
    <main>

        <MetaData
            title={'Contact Us - Iditium'}
            description={`
                Get in touch with Iditium for expert website development, mobile app creation, 
                cloud solutions, custom software, and consulting services. We're here to help you achieve your digital goals.
            `}
        />

        <HeroComponent heroText={'CONTACT US'} />

        <section className={`${styles.grid} ${styles.grid__1x2} ${contactStyles.contact__container}`}>
            <div data-aos="fade-up" className={contactStyles.contact__information}>
                <span className={contactStyles.contact__heading}>Contact Details</span>
                <IconText icon={'fa-solid fa-location-dot'} text={'223F Blaauwberg Road, Table View'} />
                <IconText icon={'fa-solid fa-phone'} text={'082 510 1207'} />
                <IconText icon={'fa-solid fa-envelope'} text={'info@example.com'} />

                {/* <div className={contactStyles.contact__company}>
                    <span className={contactStyles.contact__heading}>Company Details</span>
                    <IconText icon={'fa-solid fa-book'} text={'00000000000'} />
                    <IconText icon={'fa-solid fa-pen-to-square'} text={'082 510 1207'} />
                    <IconText icon={'fa-solid fa-envelope'} text={'info@example.com'} />
                </div> */}

                <p className={contactStyles.contact__text}>We are available, please call the office number above.</p>
            </div>


            <div data-aos="fade-up" className={contactStyles.contact__map}>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3314.318345725774!2d18.507529710470138!3d-33.82989997312992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1dcc5f25850c3747%3A0x24a263d7f20acfd2!2s223F%20Blaauwberg%20Rd%2C%20Table%20View%2C%20Cape%20Town%2C%207439!5e0!3m2!1sen!2sza!4v1704127128412!5m2!1sen!2sza" 
                    style={{ ...mapStyles }}
                    allowFullScreen={false}
                    loading="lazy" 
                    referrerPolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </section>

        <section className={`${contactStyles.questions__container} ${styles.center__content} ${styles.text__center}`}>
            <h2>HAVE SOME QUESTIONS</h2>
            <p> 
                Ready to transform your vision into reality? Let Iditium's digital wizards craft your success story. 
                Get in touch and share your dreams.
            </p>

            <section className={`${styles.grid} ${styles.grid__1x2}`}>
                <div data-aos="flip-left" className={contactStyles.questions__img}>
                    <img 
                        src='/assets/contact-us-image.png' 
                        className={contactStyles.contact__img} 
                        alt="Enhance your online presence with our professional website, mobile app, 
                            and cloud infrastructure solutions. Our dedicated team, depicted in this image, efficiently handles emails and calls, 
                            ensuring seamless communication and superior customer engagement for your business" />
                </div>

                <form id="form" data-aos="flip-right" className={contactStyles.questions__form} onSubmit={submitHandler}>
                    <input
                        type="name"
                        placeholder='Full Name'
                        name='name'
                        className={contactStyles.contact__input}
                        value={name}
                        onChange={onChangeValue}
                    />
                    <input
                        type="email"
                        placeholder='Email Address'
                        name='email'
                        className={contactStyles.contact__input}
                        value={email}
                        onChange={onChangeValue}
                    />
                    <input
                        type="text"
                        placeholder='Telephone Number'
                        name='phoneNumber'
                        className={contactStyles.contact__input}
                        value={phoneNumber}
                        onChange={onChangeValue}
                    />
                    <input
                        type="text"
                        placeholder='Subject'
                        name='subject'
                        className={contactStyles.contact__input}
                        value={subject}
                        onChange={onChangeValue}
                    />
                    <textarea
                        placeholder='Write Details'
                        name='details'
                        className={`${contactStyles.contact__input} ${contactStyles.contact__textarea}`}
                        value={details}
                        onChange={onChangeValue}
                    />
                    <Button 
                        type={'submit'} 
                        title={'Submit'} 
                        onClick={() => {}} 
                        buttonType={'primary'} 
                        classNames={contactStyles.contact__button} 
                        maxWidth={true}
                    />
                </form>
                {/* eslint-disable-next-line no-extra-parens */}
                {error && (<p className={contactStyles.error__text}>{error}</p>)}
            </section>
        </section>

        <ClientContainer />

        <MeetTheTeamContainer />

        <Footer />
    </main>
  );
};

function IconText(props: { icon: string, text: string}) {
    return (
        <p className={contactStyles.contact__text}>
            <i className={props.icon}></i>
            {'  ' + props.text}
        </p>
    );
}

const mapStyles: CSSProperties = {
    width: '100%',
    height: '100%',
    border: 0
  };

export default ContactUs;