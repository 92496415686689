import React from 'react';
import Button from './inputs/button';
import styles from '../styles/styles.module.css';
import informationStyles from '../styles/components/information-box.module.css';
import { useLocation, useNavigate } from 'react-router-dom';

interface InformationProps {
    subheading: string;
    heading: string;
    paragraph: string;
    redBackground?: boolean;

}

const InformationComponent = (props: InformationProps) => {
    const navigate = useNavigate();
    const location = useLocation();

    function navigateToContactUs() {
        if(!location.pathname.includes('contact-us')) {
            navigate('/contact-us');
        }
    }
    
  return (
    <section 
        data-aos="fade-down" 
        className={`
            ${styles.center__content} 
            ${informationStyles.information__container} 
            ${props.redBackground ? styles.blue_background : ''}
        `}>
        
        <section className={informationStyles.information__image}>
            <img 
                src="/assets/about-us.png" 
                alt="Professional woman achieving client results with focused work on a computer. 
                Experience excellence and client success with our dedicated team at Iditium." 
                className={informationStyles.img}
            />
        </section>

        <section id="information" className={informationStyles.information__overlay}>
            <div className={`${styles.ellipse} ${informationStyles.ellipse_top_left}`}></div>
            <h3>{props.subheading}</h3>
            <h2>{props.heading}</h2>
            <p>
                {props.paragraph}
            </p>
            <Button title={'More Details'} onClick={navigateToContactUs} buttonType={'primary'} classNames={informationStyles.btn__spacing}/>
            <Button title={'Contact Us'} onClick={navigateToContactUs} buttonType={'secondary'} />
            <div className={`${styles.ellipse} ${informationStyles.ellipse_bottom_right}`}></div>
        </section>

    </section>
  );
};

export default InformationComponent;