import React from 'react';
import styles from '../styles/styles.module.css';
import heroStyles from '../styles/components/hero.module.css';
import Header from './layouts/header';

interface HeroProps {
    heroText: string;
}

const HeroComponent = (props: HeroProps) => {
    return (
        <>
            <section className={heroStyles.header}>
                <Header />
            </section>

            <section className={`${heroStyles.background} ${styles.center__content}`}>
                <p className={styles.text__center}>
                    <h1 data-aos="zoom-in">{props.heroText}</h1>
                </p>
            </section>
        </>
    );
  };
  
  export default HeroComponent;