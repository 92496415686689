import React from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

interface VideoModalProps {
    isVideoVisible: boolean;
    handleVideoToggle: () => void;
}


const VideoModal = (props: VideoModalProps) => {
  return (
    <>
        {props.isVideoVisible && 
            <Modal
                isOpen={props.isVideoVisible}
                onRequestClose={props.handleVideoToggle}
                contentLabel="Iditium Demo"
                style={modalStyles}
            >
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/JLNjVT8kQi8?si=8kLJz_XbEp9I63kw" 
                    title="YouTube video player" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen={true}>
                  
                </iframe>
            </Modal>
        }
    </>
  );
};

const modalStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
};

export default VideoModal;