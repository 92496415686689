import React, { useState } from 'react';
import faqStyles from '../styles/components/frequently-asked-questions.module.css';
import styles from '../styles/styles.module.css';

interface FAQProps {
    question: string;
    answer: string;
}

const FAQComponent = (props: FAQProps) => {
    const [show, setShow] = useState(false);

    const showQuestion = () => {
        setShow(!show);
    };

    return (
        <section className={`${faqStyles.faq__container} ${show ? styles.collapsible__expanded : ''}`}>
            <div onClick={showQuestion} className={faqStyles.questions__container}>
                <h4>{props.question}</h4>
                {/* eslint-disable-next-line no-extra-parens */}
                {show ? (
                    <svg xmlns="http://www.w3.org/2000/svg" className={faqStyles.faq__exit__icon}>
                        <use xlinkHref="../../assets/sprite.svg#exit-cross"></use>
                    </svg>
                // eslint-disable-next-line no-extra-parens
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" className={faqStyles.faq__icon}>
                        <use xlinkHref="../../assets/sprite.svg#plus"></use>
                    </svg>
                )}

            </div>

            <div className={styles.collapsible__content}>
                <p className={faqStyles.faq__paragraph}>
                    {props.answer}
                </p>
            </div>
        </section>
    );
};

export default FAQComponent;