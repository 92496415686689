import React from 'react';
import { useNavigate } from 'react-router-dom';
import softwareStyles from '../styles/components/software-container.module.css';
import styles from '../styles/styles.module.css';
import Button from './inputs/button';

const SoftwareContainer = () => {
  const navigate = useNavigate();

  function navigateToContactUs() {
      navigate('/contact-us');
  }

  return (
    <section 
        data-aos="fade-up"     
        className={`${softwareStyles.development__container} ${styles.grid} ${styles.grid__1x2}`}
    >
        <section className={softwareStyles.development__info}>
          <h3 className={softwareStyles.development__heading}>IDITIUM</h3>
          <h4 className={softwareStyles.development__software__header}>Software Development</h4>
        </section>

        <section className={softwareStyles.development__info}>
        <p className={softwareStyles.development__text}>
          At Iditium, we specialize in crafting exceptional digital solutions. Our team of skilled developers 
          and designers create stunning websites, user-friendly mobile apps, robust software, and scalable cloud 
          infrastructure. With a focus on innovation and user experience, we strive to deliver tailored solutions that 
          exceed our clients' expectations. Trust Iditium to bring your ideas to life and empower your business with cutting-edge technology.
        </p>
        <Button title={'Contact Now'} onClick={navigateToContactUs} buttonType={'header'} />
        </section>
    </section>
  );
};

export default SoftwareContainer;