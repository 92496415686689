import React, { useState } from 'react';
import Modal from 'react-modal';
import aboutUsStyles from '../styles/pages/about-us.module.css';
import styles from '../styles/styles.module.css';
import Button from '../components/inputs/button';
import Footer from '../components/layouts/footer';
import { ClientContainer } from '../components/client-container';
import MeetTheTeamContainer from '../components/meet-the-team';
import HeroComponent from '../components/hero';
import InformationComponent from '../components/information-box';
// import { useNavigate } from 'react-router-dom';
import SoftwareContainer from '../components/software-container';
import VideoModal from '../components/modal';
import MetaData from '../components/layouts/meta-data';

Modal.setAppElement('#root');

const AboutUs = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);

  const handleVideoToggle = () => {
    setIsVideoVisible(!isVideoVisible);
};

  return (
      <main>

          <MetaData
            title="About Us - Iditium: Innovators in Web, Mobile App, Cloud Solutions, and Custom Software"
            description={`
              Learn about Iditium, a leading provider of innovative website development, mobile app creation, 
              cloud solutions, custom software, and consulting services. Discover our mission, values, 
              and the team driving digital transformation.`}
          />
          
          {/* HERO */}
          <HeroComponent heroText={'ABOUT US'} />

          {/* History */}
          <section className={`${styles.grid} ${styles.grid__1x2} ${styles.overflow__hidden}`}>
            <div data-aos="flip-up" className={aboutUsStyles.grid__column__1}>
              <h3>History</h3>
              <h2>IDITIUM History</h2>
              <p>
                Born from a founder's bold dream in 2011, Iditium wasn't just a software company, 
                it was a bridge between ideas and reality. Fuelled by a relentless desire to build people's dreams, 
                Iditium quickly found itself at the forefront of a rapidly evolving tech landscape. 

              </p>
              <p>
                From humble beginnings, it blossomed into a global force, 
                exceeding the founder's wildest ambitions with over 49 satisfied clients across the world. 
                Today, Iditium stands as a testament to the power of passion and innovation, 
                continuing to shape the future one ambitious dream at a time.
              </p>
              {/* <Button title={'View Details'} onClick={navigateToServices} buttonType={'primary'} /> */}
            </div>

            <div data-aos="flip-up" className={`${aboutUsStyles.grid__column__2} ${styles.center__content}`}>
                <img src="/assets/iditium-history.png" 
                  alt="Driven professional achieving client results through focused work 
                      on a computer. Experience success with our dedicated team at Iditium" 
                  className={aboutUsStyles.img}/>
            </div>
          </section>

        {/* Logos */}
        <ClientContainer />

        {/* Services */}
        <InformationComponent 
            subheading={'Services'} 
            heading={'Comprehensive Custom Solutions'} 
            paragraph={`
                      We empower businesses and individuals to navigate the ever-evolving digital landscape with confidence. 
                      We craft bespoke software solutions, from sleek mobile apps and user-friendly websites to robust cloud 
                      infrastructure and cutting-edge software development. Our team of tech wizards partners with you, transforming 
                      your vision into a tangible reality that unlocks possibilities and propels your success.
          `} 
        />


        {/* Content */}
        <section className={`${styles.grid} ${styles.grid__1x2} ${styles.overflow__hidden}`}>
          <div data-aos="fade-right" className={`${aboutUsStyles.grid__column__1} ${styles.blue__background}`}>
            <h3>Vision</h3>
            <h2>Our Vision</h2>
            <p>
              At Iditium, we envision a world where technology seamlessly blends with human potential, empowering individuals 
              and businesses to achieve the seemingly impossible. We see ourselves as architects of the digital future, 
              where innovative software solutions become the bridge between dreams and reality. Driven by this vision, we 
              strive to craft elegant and powerful tools that not only meet your needs, 
              but transcend them, propelling you towards unprecedented success.
            </p>
          </div>

          <div data-aos="fade-left" className={`${aboutUsStyles.grid__column__2} ${styles.blue__background}`}>
            <img 
              src="/assets/iditium-team-vision.png" 
              alt="Innovative team providing visionary solutions to clients, showcasing expertise and collaborative excellence. 
                Elevate your business with our forward-thinking approach at Iditium." 
              className={aboutUsStyles.img}
            />
          </div>
        </section>

        <section className={`${styles.grid} ${styles.grid__1x2} ${styles.overflow__hidden}`}>
          <div data-aos="fade-right" className={`${aboutUsStyles.grid__column__2} ${styles.reverse__layout}` }>
            <img 
              src="/assets/iditium-mission-statement.png" 
              alt="Unified team achieving their mission statement, exemplifying collaboration and success. 
                  Join our journey of accomplishment at Iditium for impactful results and shared goals" 
              className={aboutUsStyles.img}/>
          </div>
          
          <div data-aos="fade-left" className={aboutUsStyles.grid__column__1}>
            <h3>Mission</h3>
            <h2>Our Mission</h2>
            <p>
              At Iditium, our mission transcends coding and pixels. We are driven by a singular purpose: 
              to unleash the transformative power of technology. We forge partnerships with our clients, 
              crafting tailored software solutions that propel businesses forward and ignite the dreams of individuals. 
              Our tools empower, our expertise guides, and our unwavering commitment ensures that every digital journey 
              we embark on together leads to unbounded innovation and lasting success
            </p>
          </div>
        </section>

        {/* Software Development */}
        <SoftwareContainer />

        {/* Meet the team */}
        <MeetTheTeamContainer />

        {/* Industry Expert */}
        <section className={`${styles.grid} ${styles.grid__1x2} ${aboutUsStyles.industry__container}`}>

          <section className={aboutUsStyles.grid__column__1}>
            <h3>Industry Expert</h3>
            <h2 className={aboutUsStyles.industry__center}>Develop With Industry Experts</h2>
            <p>

            Entrusting your digital dreams to just anyone is risky. At Iditium, we're not just developers, we're partners 
            in innovation. Our team of experts brings more than just technical prowess - they bring a keen 
            understanding of your vision and a relentless commitment to turning it into reality. We don't simply code, 
            we engineer solutions that empower your business to soar.
            </p>
            <Button title={'Watch Demo'} onClick={handleVideoToggle} buttonType={'primary'} /> 
            <VideoModal 
              isVideoVisible={isVideoVisible} 
              handleVideoToggle={handleVideoToggle} 
            />
          </section>

          <section data-aos="fade-left" className={aboutUsStyles.industry__column}>
            <img 
              src="/assets/lady-on-macbook.png" 
              alt="Professional woman working on a MacBook, showcasing productivity and efficiency 
                in a modern workspace. Elevate your digital work environment with our 
                cutting-edge solutions at Iditium." 
              className={`${aboutUsStyles.img} ${aboutUsStyles.industry__img}`}
            />
          </section>

        </section>

        {/* Footer */}
        <Footer />
      </main>
  );
};

export default AboutUs;