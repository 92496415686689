import React from 'react';
import featureBenefitStyles from '../styles/components/feature-benefit.module.css';

interface FeatureBenefitProps {
    title: string;
    description: string;
    reversed?: boolean;
}
const FeatureBenefit = (props: FeatureBenefitProps) => {
    return (
        <div className={props.reversed ? featureBenefitStyles.feature__benefit__reversed : featureBenefitStyles.feature__benefit}>
            <h4>{props.title}</h4>
            <p className={featureBenefitStyles.feature__text}>
                {props.description}
            </p>
        </div>
    );
};

export default FeatureBenefit;